// (C) Copyright 2020-2022, 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  CheckBox,
  DataTable,
  TextInput,
} from 'grommet';
import { Pause, Refresh, Resume, Search } from 'grommet-icons';
import IconButton from './generic/IconButton';
import TableSettings from './TableSettings';
import TextInputDebounce from '../containers/generic/MetaForm/TextInputDebounce';

// TODO: these aren't really "settings" so much as a table header + view settings, right?
export const Settings = ({
  allowPause,
  pauseRefresh,
  poll,
  visibleColNames,
  columnMetadata,
  onChange,
  onFilter,
  filter,
  meta,
}) => {
  const [query, setQuery] = useState(filter);

  return (
    <Box pad={{ bottom: 'small' }} fill='horizontal'>
      <Box gap='medium' direction='row' justify='start'>
        <Box>
          <TextInputDebounce
            width='medium'
            type='search'
            placeholder='Search'
            icon={<Search />}
            value={query}
            onChange={(event) => {
              const { value: newValue } = event.target;
              setQuery(newValue);
              onFilter(newValue);
            }}
          />
        </Box>
        <Box alignSelf='end' direction='row' gap='xsmall'>
          {!allowPause && (
            <IconButton
              icon={<Refresh />}
              kind='toolbar'
              onClick={() => meta.fetchData()}
              tip='Refresh'
            />
          )}
          {allowPause && (
            <IconButton
              icon={poll ? <Pause /> : <Resume />}
              kind='toolbar'
              onClick={() => pauseRefresh(poll)}
              tip={poll ? 'Pause polling' : 'Resume polling'}
            />
          )}
          <TableSettings
            columnMetadata={columnMetadata}
            meta={meta}
            onChange={onChange}
            visibleColNames={visibleColNames}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const HeaderCheckBox = (props) => (
  <Box
    margin={{ left: '3px' }}
    className='select-checkbox'
  >
    <CheckBox {...props} />
  </Box>
);

export const StyledDataTable = styled(DataTable)`
  th:first-child { 
    width: ${({ noSelectColumn }) => noSelectColumn ? 'auto' : '36px' };
  }

  td:hover .action-buttons button {
    background: transparent;
  }

  td .action-buttons button:hover {
    background: ${({ theme }) => {
      const { light, dark } = theme.global?.colors['background-contrast'] || {};
      return theme.dark ? dark : light;
    }};
  }

  button,
  label,
  input + div {
    border-radius: 4px;
  }

  .select-checkbox label {
    padding: 0 6px;
  }

  thead {
    tr {
      th {
        white-space: nowrap;
      }
    }
  }

  tbody {
    tr {
      th,
      td {
        border-bottom: 1px solid
          ${({ theme }) =>
            theme.dark
              ? theme.global.colors.background.dark
              : theme.global.colors.background.light};
      }

      &:nth-child(odd) {
        &:hover {
          td,
          th {
            background: ${({ theme }) =>
              theme.dark
                ? theme.table.zebra.dark.odd.hover
                : theme.table.zebra.light.odd.hover};
          }
        }
      }

      &:nth-child(even) {
        &:hover {
          td,
          th {
            background: ${({ theme }) =>
              theme.dark
                ? theme.table.zebra.dark.even.hover
                : theme.table.zebra.light.even.hover};
          }
        }
      }
    }
  }
`;
