// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';
import TextField from './TextField';
import { toDateTime } from '../../lib/formatters';

export default function DateField(props) {
  const { label, value } = props;

  const dateValue = useMemo(() => (value ? toDateTime(value) : '--'), [value]);

  return <TextField label={label} value={dateValue} />;
}
