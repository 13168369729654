// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Button, Heading, Box } from 'grommet';
import {
  CloudDownload,
  DocumentMissing,
  DocumentUpdate,
  Download,
  Trash,
} from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import auth from '../lib/auth';
import IconButton from './generic/IconButton';

const ViewHeader = ({
  children,
  controls,
  createLabel,
  extraActions = null,
  onCreate,
  onDelete,
  onImport,
  onPublish,
  onUpdate,
  onUnpublish,
  level = 2,
}) => {
  const history = useHistory();
  const label = typeof createLabel === 'boolean' ? '' : createLabel;
  const isViewer = auth.activeRoleDef?.id?.includes('_viewer');

  const handleCreate = () => {
    if (onCreate) {
      onCreate();
    } else {
      history.push(`${history.location.pathname}/create`);
    }
  };

  return (
    <Heading
      level={level}
      fill={true}
      size='large'
      weight='normal'
      style={{ position: 'relative' }}
    >
      <Box direction='row' justify='between'>
        <Box alignSelf='start' data-testid='heading'>
          {children}
        </Box>
        <Box alignSelf='end' direction='row'>
          <Box direction='row' margin={{ top: 'xsmall' }}>
            {controls}
            {onDelete && (
              <IconButton
                icon={<Trash color='brand' />}
                onClick={onDelete}
                tip='Delete'
              />
            )}
            {onImport && (
              <IconButton
                title='Import'
                icon={<CloudDownload />}
                onClick={onImport}
              />
            )}
            {onUpdate && (
              <IconButton
                title='Update'
                icon={<Download />}
                onClick={onUpdate}
              />
            )}
            {onUnpublish && (
              <IconButton
                icon={<DocumentMissing color='brand' />}
                onClick={onUnpublish}
                tip='Unpublish'
              />
            )}
            {onPublish && (
              <IconButton
                icon={<DocumentUpdate color='brand' />}
                onClick={onPublish}
                tip='Publish'
              />
            )}
          </Box>
          <Box direction='row' gap='small'>
            {extraActions}
            {!isViewer && createLabel && (
              <Button
                label={label}
                secondary={!extraActions}
                primary={!!extraActions}
                onClick={handleCreate}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Heading>
  );
};

export default ViewHeader;
