// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useState } from 'react';
import { Box, Button, Grid, Heading, Layer } from 'grommet';
import TextField from '../../components/view/TextField';
import CheckboxField from '../../components/view/CheckboxField';
import TableField from '../../components/view/TableField';

const TABLE_TYPES = ['gpt' /* , "dos", "sun", "sgi" */]; // Currently only support gpt

const DISK_PARTITION_TYPES = [
  'swap',
  'ext2',
  'ext3',
  'ext4',
  'xfs',
  'btrfs',
  'efi',
  'bios',
  'basic',
  'lvm',
  'luks',
  'vmdiag',
  'vmfs',
];

export default function LayoutViewModal(props) {
  const { data } = props;

  const [modal, setModal] = useState(null);

  const handleClose = () => {
    setModal(null);
  };

  return (
    <Box gap={'medium'}>
      <Heading level={3} margin={'none'}>
        Layouts
      </Heading>
      <Grid
        columns={['auto', '1fr']}
        gap={{
          row: 'small',
          column: 'large',
        }}
        fill='horizontal'
      >
        <TextField label={'Device'} value={data.device} />
        <TextField label={'Description'} value={data.description} />
        <TextField label={'Start offset'} value={data.start_offset} />
        <TextField label={'Table type'} value={TABLE_TYPES[data.table_type]} />
        <TableField
          columns={[
            {
              property: 'type',
              header: 'Partition type',
            },
            {
              property: 'mount',
              header: 'Mount',
            },
            {
              property: 'formula.equation',
              header: 'Size',
              render: ({ formula: { equation } }) => equation ?? 'free disk',
            },
            {
              property: 'formula.min_size',
              header: 'Min',
            },
            {
              property: 'formula.max_size',
              header: 'Max',
            },
            {
              property: 'bootable',
              header: 'Boot',
              render: ({ bootable }) => (!bootable ? '' : '*'),
            },
          ]}
          onClickRow={({ datum }) => setModal(datum)}
          value={data?.partitions}
        />
      </Grid>

      {modal ? (
        <Layer
          position='center'
          onClickOutside={handleClose}
          onEsc={handleClose}
        >
          <Box pad={'medium'} width={'800px'}>
            <Box gap={'medium'}>
              <Heading level={3} margin={'none'}>
                Partitions
              </Heading>
              <Grid
                columns={['auto', '1fr']}
                gap={{
                  row: 'small',
                  column: 'large',
                }}
                fill='horizontal'
              >
                <TextField
                  label={'Type'}
                  value={DISK_PARTITION_TYPES[modal.type]}
                />
                <TextField label={'Equation'} value={modal.formula.equation} />
                <TextField label={'Min size'} value={modal.formula.min_size} />
                <TextField label={'Max size'} value={modal.formula.max_size} />
                <TextField label={'Mount point'} value={modal.mount} />
                <TextField label={'Name'} value={modal.name} />
                <TextField label={'Partition ID'} value={modal.partition_id} />
                <CheckboxField label={'Bootable'} value={modal.bootable} />
                <TextField label={'Make options'} value={modal.make_options} />
                <TextField
                  label={'Mount options'}
                  value={modal.mount_options}
                />
              </Grid>
            </Box>
            <Box direction={'row'} pad={{ top: 'medium', bottom: 'small' }}>
              <Button label='Close' onClick={handleClose} secondary />
            </Box>
          </Box>
        </Layer>
      ) : null}
    </Box>
  );
}
