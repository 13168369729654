// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import * as c from './consts.js';
import auth from '../lib/auth';

import Welcome from '../views/Welcome';
import { MachineListView } from '../views/MachinesView';
import { MachineItemView } from '../views/MachinesView/item.js';
import {
  MachineSizeListView,
  MachineSizeItemView,
  MachineSizeCreateView,
  MachineSizeEditView,
} from '../views/MachineSizesView';
import {
  MachineTypeListView,
  MachineTypeItemView,
  MachineTypeCreateView,
  MachineTypeEditView,
} from '../views/MachineTypesView';
import {
  FWBaselinesListView,
  FWBaselinesCreateView,
  FWBaselinesItemView,
  FWBaselinesEditView,
} from '../views/FWBaselinesView';

import {
  HostListView,
  HostItemView,
  HostCreateView,
  HostEditView,
} from '../views/HostsView';
import HostConsoleLogsView from '../views/HostsView/HostConsoleLogsView.js';
import {
  PodListView,
  PodItemView,
  PodCreateView,
  PodEditView,
} from '../views/PodsView';
import {
  NetworkListView,
  NetworkItemView,
  NetworkCreateView,
  NetworkEditView,
} from '../views/NetworksView';
import {
  RackListView,
  RackItemView,
  RackCreateView,
  RackEditView,
} from '../views/RacksView';
import {
  RackControllerItemView,
  RackControllerListView,
} from '../views/RackController';
import {
  RackTemplateListView,
  RackTemplateItemView,
  RackTemplateCreateView,
  RackTemplateEditView,
} from '../views/RackTemplate';
import {
  TagListView,
  TagItemView,
  TagCreateView,
  TagEditView,
} from '../views/TagsView';
import { OpListView, OpItemView } from '../views/OpsView';

import {
  SSHKeyListView,
  SSHKeyItemView,
  SSHKeyCreateView,
} from '../views/SshkeysView';
import { SwitchListView, SwitchItemView } from '../views/SwitchesView';

import { SwitchTypeListView, SwitchTypeCreateView } from '../views/SwitchType';
import {
  SwitchPortListView,
  SwitchPortItemView,
} from '../views/SwitchPortsView';
import {
  ServiceListView,
  ServiceCreateView,
  ServiceEditView,
  HosterServiceCatalogItemView,
  HosterServiceItemView,
  HosterServiceCreateView,
  HosterServiceEditView,
} from '../views/Service';
import { HosterServiceListView } from '../views/Service/hostersvcs.js';

import {
  PortalListView,
  PortalItemView,
  PortalEditView,
} from '../views/PortalsView';
import {
  PortalMemberItemView,
  PortalMemberCreateView,
  PortalMemberEditView,
} from '../views/PortalsView/members';
import {
  ProjectListView,
  ProjectItemView,
  ProjectItemViewNoEdit,
  ProjectCreateView,
  ProjectEditView,
} from '../views/ProjectsView';
import {
  ProjectMemberItemView,
  ProjectMemberCreateView,
} from '../views/ProjectsView/members';
import { UserListView, UserItemView, UserCreateView } from '../views/UsersView';
import {
  IPPoolListView,
  IPPoolItemView,
  IPPoolCreateView,
  IPPoolEditView,
} from '../views/IpPoolsView';
import { ReleaseListView, ReleaseItemView } from '../views/ReleasesView';
import {
  ArrayListView,
  ArrayItemView,
  ArrayCreateView,
} from '../views/ArraysView';
import {
  VolumeFlavorListView,
  VolumeFlavorItemView,
  VolumeFlavorEditView,
  VolumeFlavorCreateView,
} from '../views/VolumeFlavor';
import {
  VolumeListView,
  VolumeEditView,
  VolumeItemView,
  VolumeCreateView,
} from '../views/VolumeView';
import {
  VolumeAttachmentsListView,
  VolumeAttachmentsItemView,
  VolumeAttachmentsCreateView,
} from '../views/VolumeAttachmentsView';
import {
  NetworkPoliciesListView,
  NetworkPoliciesItemView,
  NetworkPoliciesCreateView,
  NetworkPoliciesEditView,
} from '../views/NetworkPoliciesView';
import DownloadsView from '../views/DownloadsView';
import { FileListView, FileItemView } from '../views/FilesView';
import {
  HosterListView,
  HosterItemView,
  HosterCreateView,
  HosterEditView,
} from '../views/HostersView';
import {
  HosterMemberItemView,
  HosterMemberCreateView,
  HosterMemberEditView,
} from '../views/HostersView/members';
import { VolumeGroupListView } from '../views/VolumeGroupsView';
import {
  StoragePoolItemView,
  StoragePoolListView,
} from '../views/StoragePoolsView';
import {
  OrganizationListView,
  OrganizationCreateView,
  OrganizationItemView,
  OrganizationEditView,
} from '../views/OrganizationsView';
import { AlertListView, AlertItemView } from '../views/AlertsView';
import {
  StorageGroupCreateView,
  StorageGroupItemView,
  StorageGroupsListView,
} from '../views/StorageGroupsView';
import {
  StorageSystemItemView,
  StorageSystemsListView,
} from '../views/StorageSystemsView';
import debugLogger from '../lib/debug.js';
import SwitchTypeItemView from '../views/SwitchType/SwitchTypeItemView';
import ServiceItemView from '../views/Service/ServiceItemView';

// ------------------------------------
// THESE NOTES ARE OLD and do not reflect change to react-router-dom
// but are left until we can finish our refactoring to the latest
// react packages
//
//
// IMPORTANT: ORDER MATTERS
// the router matches the first route meaning if we have the following
//  - /sshkeys/
//  - /sshkeys/:id
//  - /sshkeys/create
// that sshkeys/create will never be matched since it matches /sshkeys/:id
// instead. This means always put the most specific matches first
// and then move to the more general matches.
//
// FUTURE:
// We will secure the routes in the future, but for now they are all available
// This means that if someone emails a URL and the user brings it up but their
// role does not allow it, it will load the page but the page likely
// wont have any data since the underlying REST APIs should filter out the
// data, so the users without permission to the data will see a semi-empty page
// the fix for this is to dynamically populate the routes after the roledef
// has been loaded (plus a resource key -> REST/GUI URL -> comonent) based on
// roledef permissions and desired scope type, and then dynamically render the
// routes, e.g. substituting an access denied page instead of the actual page
// for the user with insufficient permissions to view a given type of resource.
//
// This means we can't create the routes below in main.js (and pass via props)
// since the roledef would not have been created yet, but instead create them
// in Root.js once the roledef has been loaded into the redux store.
//
// we may also be able to populate the settings for each view, so we don't
// have to have a lot of individual GUI views, but instead have everything
// metadata driven (while allowing individual custom views) and/or overrides
// to be cleanly specified.
// ------------------------------------
const debug = debugLogger('CoreRoutes', false);

// do we need a redirect for the /s/ path?

const CoreRoutes = () => {
  return (
    <Switch>
      <Route path='/login' component={Welcome} />
      <Route exact path='/' component={Welcome} />
      <Route
        exact
        path={c.makeNoNavUrl(c.URL_HOST_CONSOLELOGS)}
        component={HostConsoleLogsView}
      />

      <Route exact path={c.makeSecUrl(c.URL_HOSTS)} component={HostListView} />
      <Route exact path={c.makeSecUrl(c.URL_USAGE)}>
        <Redirect to={c.makeSecUrl(c.URL_HOSTS)} />
      </Route>
      <Route exact path={c.makeSecUrl(c.URL_VOLUME_USAGE)}>
        <Redirect to={c.makeSecUrl(c.URL_VOLUMES)} />
      </Route>

      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_HOSTS)}
        component={HostCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_HOSTS)}
        component={HostEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_HOSTS)}
        component={HostItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_MACHINES)}
        component={MachineListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_MACHINES)}
        component={MachineItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_MACHINESIZES)}
        component={MachineSizeListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_MACHINESIZES)}
        component={MachineSizeCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_MACHINESIZES)}
        component={MachineSizeEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_MACHINESIZES)}
        component={MachineSizeItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_MACHINETYPES)}
        component={MachineTypeListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_MACHINETYPES)}
        component={MachineTypeCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_MACHINETYPES)}
        component={MachineTypeEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_MACHINETYPES)}
        component={MachineTypeItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_FWBASELINES)}
        component={FWBaselinesListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_FWBASELINES)}
        component={FWBaselinesCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_FWBASELINES)}
        component={FWBaselinesEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_FWBASELINES)}
        component={FWBaselinesItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_NETWORKPOLICIES)}
        component={NetworkPoliciesListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_NETWORKPOLICIES)}
        component={NetworkPoliciesCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_NETWORKPOLICIES)}
        component={NetworkPoliciesEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_NETWORKPOLICIES)}
        component={NetworkPoliciesItemView}
      />

      <Route exact path={c.makeSecUrl(c.URL_OPS)} component={OpListView} />
      <Route exact path={c.makeSecItemUrl(c.URL_OPS)} component={OpItemView} />

      <Route
        exact
        path={c.makeSecUrl(c.URL_IPPOOLS)}
        component={IPPoolListView}
      />
      <Route
        exact
        path={`${c.makeSecEditUrl(c.URL_IPPOOLS)}/:tab`}
        component={IPPoolEditView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_IPPOOLS)}
        component={IPPoolEditView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_IPPOOLS)}
        component={IPPoolCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_IPPOOLS)}
        component={IPPoolItemView}
      />

      <Route exact path={c.makeSecUrl(c.URL_FILES)} component={FileListView} />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_FILES)}
        component={FileItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_SSHKEYS)}
        component={SSHKeyListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_SSHKEYS)}
        component={SSHKeyCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_SSHKEYS)}
        component={SSHKeyItemView}
      />

      <Route exact path={c.makeSecUrl(c.URL_PODS)} component={PodListView} />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_PODS)}
        component={PodCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_PODS)}
        component={PodEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_PODS)}
        component={PodItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_NETWORKS)}
        component={NetworkListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_NETWORKS)}
        component={NetworkCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_NETWORKS)}
        component={NetworkEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_NETWORKS)}
        component={NetworkItemView}
      />

      <Route exact path={c.makeSecUrl(c.URL_TAGS)} component={TagListView} />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_TAGS)}
        component={TagCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_TAGS)}
        component={TagEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_TAGS)}
        component={TagItemView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_RACKTEMPLATES)}
        component={RackTemplateListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_RACKTEMPLATES)}
        component={RackTemplateCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_RACKTEMPLATES)}
        component={RackTemplateEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_RACKTEMPLATES)}
        component={RackTemplateItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_RACKCONTROLLERS)}
        component={RackControllerListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_RACKCONTROLLERS)}
        component={RackControllerItemView}
      />

      <Route exact path={c.makeSecUrl(c.URL_RACKS)} component={RackListView} />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_RACKS)}
        component={RackCreateView}
      />
      <Route
        exact
        path={`${c.makeSecEditUrl(c.URL_RACKS)}/:tab`}
        component={RackEditView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_RACKS)}
        component={RackEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_RACKS)}
        component={RackItemView}
      />
      <Route
        exact
        path={`${c.makeSecItemUrl(c.URL_RACKS)}/:tab`}
        component={RackItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_SWITCHES)}
        component={SwitchListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_SWITCHES)}
        component={SwitchItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_SWITCHTYPES)}
        component={SwitchTypeListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_SWITCHTYPES)}
        component={SwitchTypeCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_SWITCHTYPES)}
        component={SwitchTypeItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_SWITCHPORTS)}
        component={SwitchPortListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_SWITCHPORTS)}
        component={SwitchPortItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_SERVICES)}
        component={ServiceListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_SERVICES)}
        component={ServiceCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_SERVICES)}
        component={ServiceEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_SERVICES)}
        component={ServiceItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_HOSTER_SERVICES)}
        component={HosterServiceListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_HOSTER_SERVICES)}
        component={HosterServiceCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_HOSTER_SERVICES)}
        component={HosterServiceEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_HOSTER_SERVICES)}
        component={ServiceItemView}
      />

      <Route
        exact
        path={c.makeSecItemUrl(c.URL_HOSTER_SERVICE_CATALOG)}
        component={HosterServiceCatalogItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_PORTALS)}
        component={PortalListView}
      />
      <Route
        exact
        path={c.makeSecChildCreateUrl(c.URL_PORTALS, c.URL_MEMBERS)}
        component={PortalMemberCreateView}
      />
      <Route
        exact
        path={c.makeSecChildEditUrl(c.URL_PORTALS, c.URL_MEMBERS)}
        component={PortalMemberEditView}
      />
      <Route
        exact
        path={c.makeSecChildItemUrl(c.URL_PORTALS, c.URL_MEMBERS)}
        component={PortalMemberItemView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_PORTALS)}
        component={PortalEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_PORTALS)}
        component={PortalItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_HOSTERS)}
        component={HosterListView}
      />
      <Route
        exact
        path={c.makeSecChildCreateUrl(c.URL_HOSTERS, c.URL_MEMBERS)}
        component={HosterMemberCreateView}
      />
      <Route
        exact
        path={c.makeSecChildEditUrl(c.URL_HOSTERS, c.URL_MEMBERS)}
        component={HosterMemberEditView}
      />
      <Route
        exact
        path={c.makeSecChildItemUrl(c.URL_HOSTERS, c.URL_MEMBERS)}
        component={HosterMemberItemView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_HOSTERS)}
        component={HosterCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_HOSTERS)}
        component={HosterEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_HOSTERS)}
        component={HosterItemView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_PROJECTS)}
        component={ProjectListView}
      />
      <Route
        exact
        path={c.makeSecChildCreateUrl(c.URL_PROJECTS, c.URL_MEMBERS)}
        component={ProjectMemberCreateView}
      />
      <Route
        exact
        path={c.makeSecChildItemUrl(c.URL_PROJECTS, c.URL_MEMBERS)}
        component={ProjectMemberItemView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_PROJECTS)}
        component={ProjectCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_PROJECTS)}
        component={ProjectEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_PROJECTS)}
        component={
          auth.activeScope === c.SCOPE_PROJECT &&
          auth.activeRoleDef?.id !== 'project_owner'
            ? ProjectItemViewNoEdit
            : ProjectItemView
        }
      />
      <Route exact path={c.makeSecUrl(c.URL_USERS)} component={UserListView} />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_USERS)}
        component={UserCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_USERS)}
        component={UserItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_DOWNLOADS)}
        component={DownloadsView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_RELEASES)}
        component={ReleaseListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_RELEASES)}
        component={ReleaseItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_VOLUMES)}
        component={VolumeListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_VOLUMES)}
        component={VolumeCreateView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_VOLUMES)}
        component={VolumeEditView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_VOLUMES)}
        component={VolumeItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_VOLUME_ATTACHMENTS)}
        component={VolumeAttachmentsListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_VOLUME_ATTACHMENTS)}
        component={VolumeAttachmentsCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_VOLUME_ATTACHMENTS)}
        component={VolumeAttachmentsItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_VOLUME_FLAVOR)}
        component={VolumeFlavorListView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_VOLUME_FLAVOR)}
        component={VolumeFlavorEditView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_VOLUME_FLAVOR)}
        component={VolumeFlavorCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_VOLUME_FLAVOR)}
        component={VolumeFlavorItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_ARRAYS)}
        component={ArrayListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_ARRAYS)}
        component={ArrayCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_ARRAYS)}
        component={ArrayItemView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_VOLUME_GROUPS)}
        component={VolumeGroupListView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_STORAGE_GROUPS)}
        component={StorageGroupsListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_STORAGE_GROUPS)}
        component={StorageGroupCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_STORAGE_GROUPS)}
        component={StorageGroupItemView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_STORAGE_POOLS)}
        component={StoragePoolListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_STORAGE_POOLS)}
        component={StoragePoolItemView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_STORAGE_SYSTEMS)}
        component={StorageSystemsListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_STORAGE_SYSTEMS)}
        component={StorageSystemItemView}
      />
      <Route
        exact
        path={c.makeSecUrl(c.URL_ORGANIZATIONS)}
        component={OrganizationListView}
      />
      <Route
        exact
        path={c.makeSecCreateUrl(c.URL_ORGANIZATIONS)}
        component={OrganizationCreateView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_ORGANIZATIONS)}
        component={OrganizationItemView}
      />
      <Route
        exact
        path={c.makeSecEditUrl(c.URL_ORGANIZATIONS)}
        component={OrganizationEditView}
      />

      <Route
        exact
        path={c.makeSecUrl(c.URL_ALERTS)}
        component={AlertListView}
      />
      <Route
        exact
        path={c.makeSecItemUrl(c.URL_ALERTS)}
        component={AlertItemView}
      />

      <Redirect path='*' to='/' />
    </Switch>
  );
};

export default CoreRoutes;
