// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Text } from 'grommet';

export default function TextField(props) {
  const { label, value } = props;

  return (
    <>
      <Text color={'text-strong'} weight='bold'>
        {label}
      </Text>
      <Text color={'text-strong'}>{value}</Text>
    </>
  );
}
