// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, CheckBox, FormField } from 'grommet';
import styled from 'styled-components';

const Container = styled(Box)`
  grid-column: 1 / span 2;
  max-width: 800px;

  label {
    opacity: 1;
  }

  .help-text {
    padding-left: 48px;
  }
`;

export default function CheckboxField(props) {
  const { help, label, value } = props;

  return (
    <Container>
      <CheckBox disabled={true} label={label} checked={value} />
      <Box className='help-text'>{help}</Box>
    </Container>
  );
}
