// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';
import debugLogger from '../../lib/debug';

import * as log from '../../lib/debug';

import { HosterServiceList } from './index.js';

import NavTabs from '../../components/HybridNav/NavTabs';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const debug = debugLogger('HosterServices', log.LOG_LEVEL_WARN);

export function HosterServiceListView() {
  return (
    <Box>
      <NavTabs navSection={NAV_COMPUTE} />
      <HosterServiceList />
    </Box>
  );
}
