// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Heading, Text } from 'grommet';

export default function SectionHeading(props) {
  const { description, title } = props;

  return (
    <Box
      gap={'xsmall'}
      margin={{ top: 'medium', bottom: 'small' }}
      style={{ gridColumn: '1 / span 2' }}
    >
      {title ? (
        <Heading level={3} color='text-strong' size='large' margin='none'>
          {title}
        </Heading>
      ) : null}
      {description ? <Text color='text-strong'>{description}</Text> : null}
    </Box>
  );
}
