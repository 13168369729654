// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useParams } from 'react-router-dom';
import { useGetSwitchType } from '../../services/switchTypes';
import { LABEL_SWITCHTYPES } from '../../components/HybridNav/consts';
import { URL_SWITCHTYPES } from '../../routes/consts';
import ItemView from '../../containers/generic/ItemView';
import TextField from '../../components/view/TextField';
import DateField from '../../components/view/DateField';
import SectionHeading from '../../components/view/SectionHeading';
import TableField from '../../components/view/TableField';

export default function SwitchTypeItemView() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetSwitchType(id);

  return (
    <ItemView
      homeLabel={LABEL_SWITCHTYPES}
      homeUrl={URL_SWITCHTYPES}
      isError={isError}
      isLoading={isLoading}
      title={'Switch type'}
    >
      <TextField label={'Name'} value={data?.name} />
      <TextField label={'Description'} value={data?.description} />
      <TextField label={'Switch family'} value={data?.sw_family} />
      <TextField label={'ID'} value={data?.id} />
      <DateField label={'Created'} value={data?.created} />
      <DateField label={'Modified'} value={data?.modified} />
      <TextField label={'Manufacturer'} value={data?.manufacturer} />
      <TextField label={'Part numbers'} value={data?.part_numbers} />
      <TextField
        label={'Rack controller'}
        value={data?.rack_controller ? 'embedded / external' : 'external'}
      />
      <SectionHeading
        title={'Ports'}
        description={'Switch ports descriptions'}
      />
      <TableField
        columns={[
          { property: 'name', header: 'Name' },
          {
            property: 'pluggable',
            header: 'Pluggable',
            render: ({ pluggable }) => (pluggable ? 'Yes' : 'No'),
          },
          {
            property: 'splittable',
            header: 'Splittable',
            render: ({ splittable }) => (splittable ? 'Yes' : 'No'),
          },
          {
            property: 'port_speeds',
            header: 'Port speeds',
            render: ({ port_speeds }) => port_speeds.join(', '),
          },
          { property: 'children', header: 'Children' },
          { property: 'parent', header: 'Parent' },
        ]}
        value={data?.ports}
        style={{ maxWidth: '800px' }}
      />
    </ItemView>
  );
}
