// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LABEL_SERVICES } from '../../components/HybridNav/consts';
import { URL_HOSTER_SERVICES } from '../../routes/consts';
import ItemView, { viewModalAtom } from '../../containers/generic/ItemView';
import TextField from '../../components/view/TextField';
import DateField from '../../components/view/DateField';
import SectionHeading from '../../components/view/SectionHeading';
import TableField from '../../components/view/TableField';
import { useGetService } from '../../services/services';
import { urlIcon, urlText } from '../../utils';
import CheckboxField from '../../components/view/CheckboxField';
import { useGetFWBaselines } from '../../services/fwBaselines';
import { Text } from 'grommet';
import { useAtom } from 'jotai';
import FileViewModal from './FileViewModal';
import LayoutViewModal from './LayoutViewModal';
import ClassifierViewModal from './ClassifierViewModal';

const DEPLOY = 'deploy';

const APPROACH_CLIENT = 'svc-client';
const APPROACH_IMAGE_SERVER = 'imagesvr';
const APPROACH_VIRTUAL_MEDIA = 'vmedia';

const SERVICE_APPROACHES = {
  [APPROACH_CLIENT]: 'Service Client',
  [APPROACH_IMAGE_SERVER]: 'Deprecated: Image Server',
  [APPROACH_VIRTUAL_MEDIA]: 'Virtual Media',
};

export default function ServiceItemView() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetService(id);
  const fwBaselineQuery = useGetFWBaselines();
  const [, setModal] = useAtom(viewModalAtom);

  const isDeploy = useMemo(() => data?.type === DEPLOY, [data?.type]);

  const fwBaseline = useMemo(
    () => fwBaselineQuery.data?.find(({ id }) => id === data?.fw_baseline_id),
    [data?.fw_baseline_id, fwBaselineQuery.data],
  );

  const handleRowClick =
    (ModalBody) =>
    ({ datum }) => {
      setModal({
        width: '800px',
        body: <ModalBody data={datum} />,
      });
    };

  return (
    <ItemView
      homeLabel={LABEL_SERVICES}
      homeUrl={URL_HOSTER_SERVICES}
      id={id}
      isError={isError}
      isLoading={isLoading}
      title={'Service'}
    >
      <TextField label={'Name'} value={data?.name} />
      <TextField label={'Description'} value={data?.description} />
      {isDeploy ? (
        <>
          <CheckboxField
            label={'Visible to project users when creating hosts'}
            value={data?.project_use}
          />
          <CheckboxField
            label={'Visible to hoster users when creating hosts'}
            value={data?.hoster_use}
          />
          <CheckboxField
            help={
              'Hosts shall make use of Switch-Side LAG or not based on this attribute during their deployment. In case HA is not available in a RACK then this attribute is irrelevant.'
            }
            label={'Enable Switch-Side LAG'}
            value={!data?.no_switch_lag}
          />
        </>
      ) : null}

      <TextField label={'ID'} value={data?.id} />
      <DateField label={'Created'} value={data?.created} />
      <DateField label={'Modified'} value={data?.modified} />
      <TextField label={'Type'} value={data?.type} />
      <TextField label={'Origin'} value={data?.origin} />
      <TextField label={'Organization ID'} value={data?.organization_id} />
      <TextField label={'Category'} value={data?.svc_category} />
      <TextField label={'Flavor'} value={data?.svc_flavor} />
      <TextField label={'Flavor version'} value={data?.svc_ver} />
      <TextField label={'Service timeout (seconds)'} value={data?.timeout} />
      <SectionHeading
        title={'Service approach'}
        description={
          'Describes the way that the service is to be setup and consumed. Some services will only work with specific Pod topologies.'
        }
      />
      <TextField
        label={'Approach'}
        value={SERVICE_APPROACHES[data?.approach]}
      />
      <SectionHeading
        title={'Files'}
        description={'Files to be used by the service.'}
      />
      <TableField
        columns={[
          { property: 'path', header: 'Relative path' },
          {
            property: 'file_size',
            header: 'File size',
          },
          {
            property: 'secure_url',
            header: 'Security',
            render: urlIcon,
          },
          {
            property: 'display_url',
            header: 'URL',
            render: urlText,
          },
        ]}
        onClickRow={handleRowClick(FileViewModal)}
        value={data?.files}
      />
      <SectionHeading
        title={'Info'}
        description={
          'Describes emedded contents that can be converted into file that, depending on the service approach.'
        }
      />
      <TableField
        columns={[
          {
            property: 'path',
            header: 'Path',
            render: ({ path }) => (
              <Text style={{ whiteSpace: 'nowrap' }}>{path}</Text>
            ),
          },
          {
            property: 'target',
            header: 'Target',
          },
          {
            property: 'templating',
            header: 'Templating',
          },
          {
            property: 'templating_input',
            header: 'Templating input',
          },
        ]}
        value={data?.info}
      />

      <SectionHeading
        title={'Imaging preparation steps'}
        description={
          'To perform imaging preparation, actions are organized into steps. This may include updating firmware.'
        }
      />
      <TableField
        columns={[
          { property: 'operation', header: 'Operation' },
          {
            property: 'parameters',
            header: 'Parameters',
            render: ({ parameters }) =>
              parameters?.map(({ name, value }) => `${name}=${value}`),
          },
        ]}
        value={data?.user_defined_steps.imgprep_steps}
      />

      <SectionHeading
        title={'Imaging complete steps'}
        description={
          'To perform imaging complete, actions are organized into steps. This may include updating firmware.'
        }
      />
      <TableField
        columns={[
          { property: 'operation', header: 'Operation' },
          {
            property: 'parameters',
            header: 'Parameters',
            render: ({ parameters }) =>
              parameters?.map(({ name, value }) => `${name}=${value}`),
          },
        ]}
        value={data?.user_defined_steps.imgcomplete_steps}
      />

      <SectionHeading
        title={'Maintenance steps'}
        description={
          'To perform machine maintenance, actions are organized into steps. This may include updating firmware.'
        }
      />
      <TableField
        columns={[
          { property: 'operation', header: 'Operation' },
          {
            property: 'parameters',
            header: 'Parameters',
            render: ({ parameters }) =>
              parameters?.map(({ name, value }) => `${name}=${value}`),
          },
        ]}
        value={data?.user_defined_steps.mtc_steps}
      />

      <SectionHeading
        title={'Firmware Baseline'}
        description={
          'The Firmware Baseline is installed by update firmware. Update firmware can be defined in the Maintenance, Imaging Prep, and Imaging Complete steps.'
        }
      />
      <TextField label={'Approach'} value={fwBaseline?.name} />

      <SectionHeading
        title={'Restricted use'}
        description={
          'Set restricted use for this service to the selected projects or classifiers below. If no restrictions are set the service is available to everyone.'
        }
      />
      <TextField label={'Permitted'} value={data?.permitted_projects} />

      <SectionHeading
        title={'Rules (Machine attributes)'}
        description={
          'Rules restrict which machines are allowed to use this OS service. This can affect the available machines in Host Create.'
        }
      />
      <TableField
        columns={[{ property: 'name', header: 'Name' }]}
        onClickRow={handleRowClick(ClassifierViewModal)}
        value={data?.classifiers}
      />

      <SectionHeading
        title={'Partitions'}
        description={'Device partitioning information.'}
      />
      <TableField
        columns={[
          {
            header: 'Device',
            property: 'device',
            render: ({ device }) => (
              <Text style={{ whiteSpace: 'nowrap' }}>{device}</Text>
            ),
          },
          { property: 'description', header: 'Description' },
          { property: 'table_type', header: 'Partitioning' },
        ]}
        value={data?.device_layouts}
        onClickRow={handleRowClick(LayoutViewModal)}
      />
    </ItemView>
  );
}
