// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const URL_MAX_LENGTH = 1024;
export const ASCII_PRINTABLE = /^[0-9a-zA-Z-_]+$/;
export const UNICODE_PRINTABLE =
  /^[A-Za-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/;

export const VLAN = /^\d{1,4}$/;
export const VLAN_MSG =
  'must be a valid VLAN ID in the format nnnn, in the range 1-4094';
export const VLAN_MIN = 1;
export const VLAN_MAX = 4094;

export const VNI = /^\d{1,8}$/;
export const VNI_MSG =
  'must be a valid VNI in the format nnnnnnnn, in the range 1-16777215';

export const VLAN_POOL = /^(\d{1,4}:\d{1,4})(,\s*\d{1,4}:\d{1,4})*$/;
export const VLAN_POOL_MSG =
  'must be a comma separated list of VLAN pool ranges in the format nnnn:nnnn, nnnn:nnnn, ...';

export const LAG_POOL = /^(\d{1,3}:\d{1,3})(,\s*\d{1,3}:\d{1,3})*$/;
export const LAG_POOL_MSG =
  'must be a comma separated list of LAG IDs in the format nnn:nnn, nnn:nnn, ...';

export const VNI_MIN = 1;
export const VNI_MAX = 16777215;

export const VNI_POOL = /^(\d{1,8}:\d{1,8})(,\s*\d{1-8}:\d{1,8})*$/;
export const VNI_POOL_MSG =
  'must be a comma separated list of VNI pool ranges in the format nnnnnnnn:nnnnnnnn, nnnnnnnn:nnnnnnnn, ...';

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_MSG =
  'must be a valid email address. Example: user@domain.com';

export const IPV4_ADDRESS_PORT =
  /^((http|https):\/\/)?((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(?::\d{0,5})?\b$/;
export const IPV4_ADDRESS_PORT_MSG =
  'must be a valid IPv4 address or url with one of the following protocols: http, https followed by IPv4 address with optional port: http://nnn.nnn.nnn.nnn(:nnnnn) or nnn.nnn.nnn.nnn(:nnnnn), e.g. http://16.5.4.1:32000 or 16.5.4.1:32000';

export const IPV4_ADDRESS =
  /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/;
export const IPV4_ADDRESS_RESERVED =
  /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(0|1|255)$/;
export const IPV4_ADDRESS_MSG =
  'must be a valid IPv4 address in the format: nnn.nnn.nnn.nnn, e.g. 16.5.4.1';
export const IPV4_SUBNET_MSG =
  'must be a valid IPv4 subnet in the format: nnn.nnn.nnn.nnn, e.g. 255.255.255.0';

export const IPV4_CIDR =
  /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\/(\d|[1-2]\d|3[0-2]))$/;
export const IPV4_CIDR_MSG =
  'must be a valid IPv4 CIDR in the format nnn.nnn.nnn.nnn/nn, e.g. 192.168.1.1/24';

export const IPV4_CIDR_ONLY = /^(\/(1[6-9]|[2]\d))$/;
export const IPV4_CIDR_ONLY_MSG =
  'must be a valid IPv4 CIDR in the format /nn, e.g. /24, in the range /16-/29';

export const IPV6_ADDRESS =
  /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*/;
export const IPV6_ADDRESS_MSG = 'must be a valid IPv6 address';

export const IPV6_CIDR =
  /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\/(\d|[1-2]\d|3[0-2]))$/;
export const IPV6_CIDR_MSG = 'must be a valid IPv6 CIDR';

export const HOSTNAME =
  /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)*$/i;
export const HOSTNAME_MSG =
  'Host name must not include spaces or special characters.';
export const DOMAIN_MSG = 'Enter valid domain name.';
export const DOMAIN =
  /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;

export const SERVICE_IMAGE_URL =
  /^(http|https|sftp|docker|s3):\/\/[-a-zA-Z0-9@:%._+~#=]{2,1024}(\.[a-z0-9]{2,4})?\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
export const SERVICE_IMAGE_SECURE_URL =
  /^((\*{17})|((http|https|sftp|docker|s3):\/\/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z0-9]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)))$/;
export const SERVICE_IMAGE_URL_MSG = (type = 'service image') =>
  `must be a valid ${type} url with one of the following protocols: https, sftp, docker, s3, e.g. https://acme.com/images/img01`;

export const DECIMAL = /^\d{1,17}$|^\d{1,13}(?=.{1,5}$)\d*\.\d*\d$/;
export const DECIMAL_SMALL = /^\d{1,17}$|^\d{1,13}(?=.{1,8}$)\d*\.\d*\d$/;
export const DECIMAL_MSG = 'must be a valid decimal value';

export const VERSION = /^\d+\.\d+\.\d+$/;
export const VERSION_MSG =
  'must be a dotted decimal version string in the form n.n.n';
export const VERSION_MASK = [
  {
    regexp: /^\d+$/,
    placeholder: 'x',
  },
  { fixed: '.' },
  {
    regexp: /^\d+$/,
    placeholder: 'y',
  },
  { fixed: '.' },
  {
    regexp: /^\d+$/,
    placeholder: 'z',
  },
];

export const URL =
  /^(http|https|sftp):\/\/([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z0-9]{2,4}|((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
export const URL_MSG =
  'must be a valid url with one of the following protocols: http, https, sftp, e.g. https://acme.com/software/pkg';
export const PORT_FROM_URL =
  /^(?:[a-z][a-z\d+\-.]*:\/\/)?(?:[a-z\d\-._~%!$&'()*+,;=]+@)?(?:[a-z\d\-._~%]+|\[[a-z\d\-._~%!$&'()*+,;=:]+]):(\d+)$/;

export const UPLINKSET = /^[a-zA-Z0-9_\- ]*$/;
export const UPLINKSET_MSG =
  'must be alphanumeric, underscore, space, or hyphen characters';

export const MAC = /^([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2}$/;
export const MAC_MSG =
  'must be six hex octets separated by colons (alpha characters in lower case): 08:f1:ea:8b:26:10';

export const PROXY =
  /^(?:http(s)?:\/\/([\w.-]+:[\w.-]+@)?)[\w.-]+(?:\.[\w.-]+)+[\w:/]+$/;
export const PROXY_MSG =
  'must be a valid http or https proxy address e.g. http://proxy.firewall.com:8080/ or http://user:password@proxy.firewall.com:8080';

export const FILE_PATH = /^(\/[^/]+)+$/;
export const FILE_PATH_MSG = 'must be a valid file path with a leading "/"';

export const GIT_REPO = /^git@[A-Za-z0-9.-]+?:([\w/.-]+?)(.git)?$/i;
export const GIT_REPO_MSG = 'Please enter a valid source repository.';

export const GIT_BRANCH = /^(?!\/).(?!\/$)/;
export const GIT_BRANCH_MSG = 'Please enter a valid branch.';

export const RSA_PRIVATE_KEY =
  /(^-+BEGIN[ ]RSA[ ]PRIVATE[ ]KEY-+)[0-9A-Za-z/=+\s]+(-+END[ ]RSA[ ]PRIVATE[ ]KEY-+$)/;
export const RSA_PRIVATE_KEY_MSG = 'Please enter a valid RSA private key.';

export const PORT =
  /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
export const PORT_MSG = 'Please enter a valid port number';

export const ASN_PREFIX = /^\d{1,10}$/;
export const ASN_PREFIX_MSG =
  'must be a valid ASN prefix in the format nnnnnnnnnn, in the range 1-4294967295';
export const ASN_PREFIX_MIN = 1;
export const ASN_PREFIX_MAX = 4294967295;

export const MAC_ADDRESS = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
export const MAC_ADDRESS_MSG =
  'must be a valid MAC address in the format xx:xx:xx:xx:xx:xx';
