// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Button, Grid, Layer, Spinner } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import { atom, useAtom } from 'jotai';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import perm from '../../routes/permissions';
import { View, ViewHeader } from '../../components';
import { makeSecUrl, PUT } from '../../routes/consts';
import InlineNotification, {
  notificationAtom,
} from '../../components/view/InlineNotification';
import { useMembershipContext } from '../../utils/context/membershipContext';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  margin-top: 0;
`;

export const viewModalAtom = atom(null);

export default function ItemView(props) {
  const { children, error, homeLabel, homeUrl, isError, isLoading, title } =
    props;

  const { role } = useMembershipContext();
  const [notification, setNotification] = useAtom(notificationAtom);
  const [modal, setModal] = useAtom(viewModalAtom);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isError) {
      setNotification({
        message: error || 'Failed to fetch data.',
        status: 'critical',
      });
    }
  }, [error, isError, setNotification]);

  // biome-ignore lint: <explanation>
  useEffect(
    () => () => {
      if (!isEmpty(notification)) {
        setNotification({});
      }
    },
    [],
  );

  const backUrl = useMemo(() => makeSecUrl(homeUrl), [homeUrl]);

  const editUrl = useMemo(() => `${pathname}/edit`, [pathname]);

  const canEdit = useMemo(
    () => perm.authorized(homeUrl, PUT, role),
    [homeUrl, role],
  );

  const handleClose = () => {
    setModal(null);
  };

  return (
    <View>
      <StyledNavLink to={backUrl} activeClassName='active'>
        <Box direction='row'>
          <Box pad={{ top: '3px' }}>
            <FormPrevious />
          </Box>
          {homeLabel}
        </Box>
      </StyledNavLink>

      <ViewHeader>{title}</ViewHeader>

      <InlineNotification margin={{ bottom: 'medium' }} />

      {isLoading ? (
        <Box align={'center'} justify={'center'} height={'small'}>
          <Spinner />
        </Box>
      ) : (
        <Grid
          columns={['auto', '1fr']}
          gap={{
            row: 'small',
            column: 'large',
          }}
          fill='horizontal'
        >
          {children}
        </Grid>
      )}

      <Box direction='row' gap={'medium'} margin={{ vertical: 'medium' }}>
        {canEdit ? (
          <div>
            <Button primary href={editUrl} label={'Edit'} />
          </div>
        ) : null}
        <div>
          <Button secondary href={backUrl} label='Back' />
        </div>
      </Box>

      {modal ? (
        <Layer
          position='center'
          onClickOutside={handleClose}
          onEsc={handleClose}
        >
          <Box pad={'medium'} width={modal.width || '800px'}>
            {modal.body}

            <Box direction={'row'} pad={{ top: 'medium', bottom: 'small' }}>
              <Button label='Close' onClick={handleClose} secondary />
            </Box>
          </Box>
        </Layer>
      ) : null}
    </View>
  );
}
