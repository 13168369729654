// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Grid, Heading } from 'grommet';
import TextField from '../../components/view/TextField';
import CheckboxField from '../../components/view/CheckboxField';

export default function FileViewModal(props) {
  const { data } = props;

  return (
    <Box gap={'medium'}>
      <Heading level={3} margin={'none'}>
        Files
      </Heading>
      <Grid
        columns={['auto', '1fr']}
        gap={{
          row: 'small',
          column: 'large',
        }}
        fill='horizontal'
      >
        <TextField label={'Path'} value={data.path} />
        <TextField label={'File size'} value={data.file_size} />
        <TextField label={'Display URL'} value={data.display_url} />
        <TextField label={'Secure URL'} value={data.secure_url} />
        <TextField label={'Download timeout'} value={data.download_timeout} />
        <TextField label={'File signature'} value={data.signature} />
        <TextField label={'File hash algorithm'} value={data.algorithm} />
        <CheckboxField
          label={'Skip SSL verification'}
          value={data.skip_ssl_verify}
        />
      </Grid>
    </Box>
  );
}
