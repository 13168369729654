// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Text } from 'grommet';
import auth from '../../lib/auth';
import * as c from '../../routes/consts';
import { AddHostPanel, PROJECT_SELF } from '../../components/host/AddHostPanel';
import { useGetAvailableResources } from '../../services/hosts';
import { useGetAvailableStorageResources } from '../../services/storage';
import { useGetProjects } from '../../services/projects';
import { useGetNetworks } from '../../services/networks';

const processForm = ({
  name,
  description,
  labels,
  location: { id: locationId, country, data_center, region },
  machine_size: { id: machineSizeId, name: machineSizeName },
  networks,
  network_for_default_route: defaultRoute,
  network_untagged: networkUntagged,
  ssh_keys: sshKeys,
  image: { flavor: svcFlavor, version: svcVersion },
  volumes,
  project,
  ...rest
}) => {
  const projectId = project?.id || '';

  return {
    description,
    labels,
    location: {
      country,
      data_center,
      region,
    },
    location_id: locationId,
    machine_size_id: machineSizeId,
    machine_size_name: machineSizeName,
    name,
    network_for_default_route: defaultRoute?.value,
    network_untagged: networkUntagged.value,
    networks: [...networks.map((network) => network.value)],
    project_id: projectId === '__self' ? '' : projectId,
    service_nets_provider_mac: networks
      .filter(({ network }) => network.subtype === 'service-provider')
      .reduce(
        (acc, { value }) => ({
          // biome-ignore lint: <explanation>
          ...acc,
          [value]: rest[value] || '',
        }),
        {},
      ),
    ssh_key_ids: sshKeys.map((item) => item.key),
    svc_flavor: svcFlavor,
    svc_version: svcVersion,
    volumes: volumes ? volumes.map(({ volume }) => volume) : [],
  };
};

export default function HostCreateForm({ col }) {
  const [projectId, setProjectId] = useState('');

  const { isLoading: isResourceLoading, data: resources } =
    useGetAvailableResources(projectId);

  const {
    isLoading: isStorageLoading,
    availableStorageResources,
    getAvailableStorageResources,
  } = useGetAvailableStorageResources();

  const {
    isLoading: isProjectsLoading,
    data: projects,
    refetch,
  } = useGetProjects();

  useEffect(() => {
    getAvailableStorageResources(projectId);
  }, [getAvailableStorageResources, projectId]);

  const { data: networks } = useGetNetworks();

  const initialProject = useMemo(() => {
    const project = projects.find(({ id }) => id === projectId) ?? PROJECT_SELF;
    return { id: project.id, name: project.name };
  }, [projectId, projects]);

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);

  const setParentForm = useCallback(
    (payload) => col.mb.view.setFormValue('new_host', payload),
    [col.mb.view.setFormValue],
  );

  // biome-ignore lint: <explanation>
  useEffect(() => {
    if (inHosterScope) {
      refetch();
    }
  }, []);

  const handleChange = async (value) => {
    const processedForm = processForm(value);
    setParentForm(processedForm);
  };

  if (!resources) {
    return null;
  }

  const loading =
    (isResourceLoading && !resources) ||
    (isStorageLoading && !availableStorageResources) ||
    (isProjectsLoading && !projects);

  return loading ? (
    <Box fill align='center' justify='center' margin={{ top: '54px' }}>
      <Text>Initializing...</Text>
    </Box>
  ) : (
    <AddHostPanel
      networks={networks}
      onChange={handleChange}
      projectId={projectId}
      projects={projects}
      initialProject={initialProject}
      resources={resources}
      setProjectId={setProjectId}
      storageResources={availableStorageResources || {}}
    />
  );
}
