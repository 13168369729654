// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useState } from 'react';
import { Box, Button, Grid, Heading, Layer } from 'grommet';
import TextField from '../../components/view/TextField';
import CheckboxField from '../../components/view/CheckboxField';
import TableField from '../../components/view/TableField';
import SectionHeading from '../../components/view/SectionHeading';

const TABLE_TYPES = ['gpt' /* , "dos", "sun", "sgi" */]; // Currently only support gpt

const DISK_PARTITION_TYPES = [
  'swap',
  'ext2',
  'ext3',
  'ext4',
  'xfs',
  'btrfs',
  'efi',
  'bios',
  'basic',
  'lvm',
  'luks',
  'vmdiag',
  'vmfs',
];

export default function ClassifierViewModal(props) {
  const { data } = props;

  return (
    <Box gap={'medium'}>
      <Heading level={3} margin={'none'}>
        Rules
      </Heading>
      <Grid
        columns={['auto', '1fr']}
        gap={{
          row: 'small',
          column: 'large',
        }}
        fill='horizontal'
      >
        <TextField label={'Name'} value={data.name} />

        <SectionHeading
          title={'Rule (Machine Attributes)'}
          description={`All rules within a classifier must match a machine's attributes for a machine to match the classifier (Logical AND).`}
        />
        <TableField
          columns={[
            {
              property: 'attribute',
              header: 'Attribute',
            },
            {
              property: 'operator',
              header: 'Operator',
            },
            {
              property: 'value',
              header: 'Value',
            },
            {
              property: 'subattribute_rules',
              header: '',
              render: ({ subattribute_rules }) =>
                subattribute_rules?.join(', '),
            },
          ]}
          value={data?.rules}
        />
      </Grid>
    </Box>
  );
}
