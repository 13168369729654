// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box } from 'grommet';
import { StyledDataTable } from '../TableList';

export default function TableField(props) {
  const {
    columns,
    width = '800px',
    onClickRow,
    primaryKey = false,
    style = {},
    value,
    ...rest
  } = props;

  return (
    <Box
      style={{ gridColumn: '1 / span 2', maxWidth: width, ...style }}
      {...rest}
    >
      <StyledDataTable
        columns={columns}
        data={value}
        onClickRow={onClickRow}
        primaryKey={primaryKey}
      />
    </Box>
  );
}
