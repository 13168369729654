// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPage from '../../containers/generic/ListPage';
import { URL_HOSTER_SERVICES, URL_SERVICES } from '../../routes/consts';
import { useGetHosterServices } from '../../services/services';

const HosterServiceListView = () => (
  <ListPage
    actions={{
      allowCreate: 'Create OS image service',
      base: URL_SERVICES,
    }}
    path={URL_HOSTER_SERVICES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    useGetData={useGetHosterServices}
    dataProps={{ types: ['deploy'] }}
    title='Services'
  />
);

export default HosterServiceListView;
