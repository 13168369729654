// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormClose, FormDown, FormUp, Search, Splits } from 'grommet-icons';
import {
  Box,
  Button,
  CheckBox,
  DropButton,
  Tab,
  Tabs,
  Text,
  TextInput,
  ThemeContext,
} from 'grommet';

import { createStorage, isEmpty } from '../utils';

const Divider = styled.div`
  background: ${({ theme }) =>
    theme.dark
      ? theme.global.colors['border-weak'].dark
      : theme.global.colors['border-weak'].light};
  max-height: 1px;
  min-height: 1px;
  margin: 12px -12px;
  width: calc(100% + 18px);
`;

export default function TableSettings({
  columnMetadata,
  meta,
  onChange,
  visibleColNames,
  ...rest
}) {
  const page = rest.page ? rest.page : meta.props.view.baseUrl;
  const theme = useContext(ThemeContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [dividerIndex, setDividerIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [formColumnMetadata, setFormColumnMetadata] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(visibleColNames);
  const [tempList, setTempList] = useState(visibleColNames);
  const { get, set } = createStorage(global.localStorage);

  useEffect(() => {
    if (JSON.stringify(formColumnMetadata) !== JSON.stringify(columnMetadata)) {
      setFormColumnMetadata(columnMetadata);
    }
  }, [formColumnMetadata, columnMetadata])

  const cachedList = useMemo(() => {
    const list = get(page) || [];

    return list.filter((name) =>
      formColumnMetadata.some(({ columnName }) => columnName === name)
    );
  }, [formColumnMetadata]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const list = isEmpty(cachedList) ? visibleColNames : cachedList;
    setVisibleColumns(list);
  }, [cachedList]);

  const originalList = useMemo(() => originalListFn(cachedList, formColumnMetadata, setDividerIndex), [cachedList, formColumnMetadata]);

  const [columns, setColumns] = useState(originalList);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setColumns(originalList);
  }, [originalList, setColumns]);

  const onSelect = (name, checked) => {
    if (checked) {
      if (tempList.includes(name)) {
        const index = tempList.findIndex((colName) => colName === name);

        setVisibleColumns([
          ...visibleColumns.slice(0, index),
          name,
          ...visibleColumns.slice(index),
        ]);
      } else {
        setVisibleColumns([...visibleColumns, name]);
      }
    } else {
      setVisibleColumns(visibleColumns.filter((colName) => colName !== name));
    }
  };

  const handleClickUp = (index) => () => {
    const list = visibleColumns.slice();
    const item = list.splice(index, 1)[0];
    list.splice(index - 1, 0, item);

    setVisibleColumns(list);
  };

  const handleClickDown = (index) => () => {
    const list = visibleColumns.slice();
    const item = list.splice(index, 1)[0];
    list.splice(index + 1, 0, item);

    setVisibleColumns(list);
  };

  const handleSwitchingTab = (nextTabIndex) => {
    if (nextTabIndex === tabIndex) {
      return;
    }

    setSearch('');
    setTabIndex(nextTabIndex);

    if (!nextTabIndex) {
      const visible = visibleColumns.map((visibleColumnName) =>
        columns.find(({ columnName }) => columnName === visibleColumnName)
      );
      const hidden = columns.filter(
        ({ columnName }) => !visibleColumns.includes(columnName)
      );

      setDividerIndex(visible.length);

      setColumns([...visible, ...hidden]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleSwitchingTab(0);
    setTempList(visibleColNames);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onChange(visibleColumns);
    set(page, visibleColumns);
  }, [visibleColumns]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const filteredList = originalList.filter(({ columnName }) =>
      columnName.includes(search)
    );

    setColumns(filteredList);
  }, [search]);

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const handleClearSearch = useCallback((e) => {
    e.preventDefault();
    setSearch('');
  }, []);

  const handleEscape = () => {
    if (!search) {
      setOpen(false);
    } else {
      setSearch('');
    }
  };

  return (
    <DropButton
      dropAlign={{
        right: 'right',
        top: 'bottom',
      }}
      dropContent={
        <Box overflow='hidden'>
          <Tabs activeIndex={tabIndex} onActive={handleSwitchingTab}>
            <Tab title='Show Columns'>
              <Box
                align='start'
                overflow='auto'
                pad='xsmall'
                style={{ maxHeight: 400 }}
              >
                <Box
                  align='center'
                  border
                  direction='row'
                  margin='small'
                  round='xsmall'
                  style={{
                    minHeight: 38,
                    width: 'calc(100% - 24px)',
                  }}
                >
                  <TextInput
                    icon={<Search />}
                    onChange={handleSearchChange}
                    plain
                    value={search}
                  />
                  {search && (
                    <Button
                      icon={<FormClose />}
                      onClick={handleClearSearch}
                      style={{
                        position: 'absolute',
                        right: 16,
                      }}
                    />
                  )}
                </Box>
                {columns.map(({ columnName, displayName }, i) => (
                  <Fragment key={`${columnName}-${i}`}>
                    {dividerIndex === i && i !== 0 && <Divider theme={theme} />}
                    <CheckBox
                      label={displayName}
                      checked={visibleColumns.indexOf(columnName) !== -1}
                      onChange={({ target: { checked } }) =>
                        onSelect(columnName, checked)
                      }
                    />
                  </Fragment>
                ))}
              </Box>
            </Tab>
            <Tab title='Order Columns'>
              <Box
                align='start'
                overflow='auto'
                pad='xsmall'
                style={{ maxHeight: 400 }}
              >
                {visibleColumns.map((visibleColumnName, i) => (
                  <Box
                    align='center'
                    direction='row'
                    fill
                    justify='between'
                    key={visibleColumnName}
                    pad={{
                      horizontal: 'small',
                      vertical: 'none',
                    }}
                    style={{
                      minHeight: 36,
                    }}
                  >
                    <Box
                      align='center'
                      direction='row'
                      gap='small'
                      style={{ minHeight: 36 }}
                    >
                      <Text
                        style={{
                          minWidth: 24,
                        }}
                        weight={500}
                      >
                        {i + 1}
                      </Text>
                      <Text weight={500}>
                        {
                          columns.find(
                            ({ columnName }) => columnName === visibleColumnName
                          )?.displayName
                        }
                      </Text>
                    </Box>
                    <Box
                      align='center'
                      direction='row'
                      gap='xxsmall'
                      style={{ minHeight: 36 }}
                    >
                      <Button
                        disabled={i === 0}
                        icon={<FormUp />}
                        onClick={handleClickUp(i)}
                      />
                      <Button
                        disabled={i === visibleColumns.length - 1}
                        icon={<FormDown />}
                        onClick={handleClickDown(i)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Tab>
          </Tabs>
        </Box>
      }
      dropProps={{
        onEsc: handleEscape,
      }}
      icon={<Splits />}
      kind='toolbar'
      tip='Settings'
      onClick={() => setOpen(!open)}
      onClose={handleClose}
      open={open}
    />
  );
}

export const originalListFn = (cachedList, formColumnMetadata, setDividerIndex) => {
  let visible;
  let hidden;

  if (isEmpty(cachedList)) {
    visible = formColumnMetadata.filter(({ isDefault }) => isDefault);

    hidden = formColumnMetadata.filter(({ isDefault }) => !isDefault);
  } else {
    visible = cachedList.map((column) =>
      formColumnMetadata.find(({ columnName }) => column === columnName)
    ).filter((item) => !!item);

    hidden = formColumnMetadata.filter(
      ({ columnName }) => !cachedList.includes(columnName)
    ).filter((item) => !!item);
  }

  setDividerIndex(visible.length);

  return [
    ...visible.sort((a, b) => a.header.localeCompare(b.header)),
    ...hidden.sort((a, b) => a.header.localeCompare(b.header)),
  ];
};
