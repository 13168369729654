// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Notification } from 'grommet';
import { atom, useAtomValue } from 'jotai';

export const notificationAtom = atom({});

export default function InlineNotification(props) {
  const { message, status } = useAtomValue(notificationAtom);

  if (!message) {
    return null;
  }

  return (
    <Box {...props}>
      <Notification status={status} message={message} />
    </Box>
  );
}
